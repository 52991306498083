<template>
  <div class="px-2">
    <div class="flex flex-row items-center" :class="canAdd ? '' : 'pb-6'">
      <div class="w-full flex flex-row" style="justify-content: space-between;">
        <span class="font-bold text-md">Anticipos</span>
        <a class="text-purple-400" @click="canAdd = true">
          <strong>+ Agregar anticipo</strong>
        </a>
      </div>
    </div>
    <div v-if="canAdd" class="flex flex-col py-4 space-x-2 pb-6">
      <div class="flex flex-row">
        <div class="w-12/12 lg:w-3/5 mr-1">
          <span>Descripción del pago</span>
          <el-input
            v-model="form.advanceDescription" />
        </div>
        <div class="w-12/12 lg:w-1/5 ml-1">
          <span>Metodo de Pago</span>
          <el-select
            class="w-full"
            v-model="form.advancePaymentMethod">
            <el-option
              v-for="paymentMethod in $store.state.catalogs.paymentMethods"
              :key="paymentMethod.id"
              :label="paymentMethod.name"
              :value="paymentMethod.id">
              <div class="flex flex-row items-center">
                <span class="ml-2">
                  {{ paymentMethod.name }}
                </span>
              </div>
            </el-option>
          </el-select>
        </div>
        <div class="w-12/12 lg:w-1/5 ml-1">
          <span>Cantidad</span>
          <el-input
            v-model="form.advanceAmount"
            class="w-full el-input-text-right"
            type="number"
            placeholder="0.00" />
        </div>
      </div>
      <div class="py-2">
        <div
          v-if="errorMessage"
          class="py-2 text-right">
          <span class="text-red-500">{{ errorMessage }}</span>
        </div>
        <div class="flex flex-row justify-end space-x-2">
          <sa-button
            type="secondary"
            @click="cancel">
            Cancelar
          </sa-button>
          <sa-button
            type="primary"
            @click="save">
            Guardar
          </sa-button>
        </div>
      </div>
    </div>
    <div>
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse ">
          <thead>
            <tr>
              <th class="py-3 text-sm font-bold text-left uppercase align-middle
              border border-l-0 border-r-0 border-solid bg-blueGray-50
              text-blueGray-500 border-blueGray-100 whitespace-nowrap">
              </th>
              <th class="py-3 text-sm font-bold text-left uppercase align-middle
              border border-l-0 border-r-0 border-solid bg-blueGray-50
              text-blueGray-500 border-blueGray-100 whitespace-nowrap">
                Descripción del pago
              </th>
              <th class="py-3 text-sm font-bold text-left uppercase align-middle
              border border-l-0 border-r-0 border-solid bg-blueGray-50
              text-blueGray-500 border-blueGray-100 whitespace-nowrap">
                Metodo de Pago
              </th>
              <th class="py-3 text-sm font-bold text-left uppercase align-middle
              border border-l-0 border-r-0 border-solid bg-blueGray-50
              text-blueGray-500 border-blueGray-100 whitespace-nowrap">
                Cantidad
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="advance in order.orderAdvances" :key="advance.id">
              <td class="pr-4">
                <div class="w-full flex flex-row text-center">
                  <div class="w-1/2">
                    <button
                      title="Editar"
                      class="el-button--primary rounded-full cursor-pointer"
                      @click="onEdit(advance)">
                      <i class="bx bx-edit" style="padding: 4px; font-size: 20px;"></i>
                    </button>
                  </div>
                  <div class="w-1/2">
                    <button
                      title="Eliminar"
                      class="el-button--danger rounded-full cursor-pointer"
                      @click="onDelete(advance.id)">
                      <i class="bx bx-trash" style="padding: 4px; font-size: 20px;"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td class="py-3 text-sm align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                {{ advance.advanceDescription }}
              </td>
              <td class="py-3 text-sm align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                {{ getPaymentMethod(advance.advancePaymentMethod) }}
              </td>
              <td class="py-3 text-sm align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                {{ moneyFormat(advance.advanceAmount) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidV4 } from 'uuid';
import numbers from '@/utils/numbers';
import { state } from '../store';

export default {
  name: 'AdvanceFormBlock',
  data() {
    return {
      columns: [
        { key: 'advanceDescription', label: 'Descripción del pago', type: 'string' },
        { key: 'advanceAmount', label: 'Cantidad', type: 'money' },
      ],
      errorMessage: null,
      canAdd: false,
      order: state.order,
      form: {
        id: null,
        advanceDescription: null,
        advanceAmount: 0,
        advancePaymentMethod: null,
      },
    };
  },
  methods: {
    cancel() {
      this.canAdd = false;
      this.form = {
        id: null,
        advanceDescription: null,
        advanceAmount: 0,
        advancePaymentMethod: null,
      };
    },
    save() {
      if (this.form.advanceAmount <= 0) {
        this.errorMessage = 'Captura una cantidad de pago correcta';
        return;
      }

      if (!this.form.id) {
        const id = `~${uuidV4()}`;
        this.$emit('addAdvance', {
          id: id,
          advanceDescription: this.form.advanceDescription,
          advanceAmount: parseFloat(this.form.advanceAmount),
          advancePaymentMethod: this.form.advancePaymentMethod,
        });
      } else {
        this.$emit('changeAdvance', {
          id: this.form.id,
          advanceDescription: this.form.advanceDescription,
          advanceAmount: parseFloat(this.form.advanceAmount),
          advancePaymentMethod: this.form.advancePaymentMethod,
        });
      }

      this.cancel();
    },
    onEdit(advance) {
      this.canAdd = true;
      this.form = {
        id: advance.id,
        advanceDescription: advance.advanceDescription,
        advanceAmount: advance.advanceAmount,
        advancePaymentMethod: advance.advancePaymentMethod,
      };
    },
    onDelete(advanceId) {
      this.$confirm(`¿Está seguro que desea eliminar este anticipo?`, {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        this.$emit('removeAdvance', advanceId);
      });
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    getPaymentMethod(paymentMethodId) {
      const paymentMethod = this.$store.state.catalogs.paymentMethods.find((p) => p.id === paymentMethodId);
      if (paymentMethod) return paymentMethod.name;
      return '';
    },
  },
};
</script>
